import React, { Component } from 'react';
import BarChart from './barchart';
import GroupedBarChart from './grouped-barchart';


class Chart extends Component {

	render() {
		let chart = this.props.chart
		let publicUrl = process.env.PUBLIC_URL + '/'
		let imagealt = 'image'
		
		//console.log(`IN CHART: CHART_TYPE=${chart.chart_type}`)

		if(chart.chart_type == 'BAR'){
			
			return <BarChart chart={chart}/>
		}
		if(chart.chart_type == 'GROUPED_BAR'){
			
			return <GroupedBarChart chart={chart}/>
		}
		return <div>Unknown Chart Type:{chart.chart_type}</div>

	}
}

export default Chart