import React, { Component } from 'react';
//import { Link } from 'react-router-dom';
//import parse from 'html-react-parser';
import ChartPage from '../analytics-components/chart-page';


class Analysis_Body_Divisibly extends Component {

	render() {
		let page = this.props.page
		let publicUrl = process.env.PUBLIC_URL + '/'
		let imagealt = 'image'

		return <ChartPage page={page}/>;
		
	}
}

export default Analysis_Body_Divisibly