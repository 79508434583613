import { useState } from "react";
import { Button, Paper } from "@mui/material";
import { useForm } from "react-hook-form";
import FormContainer from "./rh_form_elements/rh-form-container";
import TextFieldElement from "./rh_form_elements/rh-text-field-element";
import PhoneFieldElement from "./rh_form_elements/rh-phone-field-element";
import EmailFieldElement from "./rh_form_elements/rh-email-field-element";
import { Stack } from "@mui/material";



export default function ContactForm(props) {
  
  
  const defaultValues = props.data?props.data:{};

 
  const [values, setValues] = useState(defaultValues)
  
  
  const formContext = useForm({
    defaultValues: defaultValues, values
  });

  const { handleSubmit, reset, control, setValue, formState: { errors } } = formContext;


  return (
    


      <Paper
        style={{
          display: "grid",
          gridRowGap: "20px",
          padding: "20px",
          //margin: "10px 300px",
          maxWidth: "500px",
        }}
      >
        <FormContainer formContext={formContext} handleSubmit={handleSubmit} >
          <Stack spacing={2}>
            <TextFieldElement required name="fullName" label="Full Name" />
            <EmailFieldElement required name="contactEmail" label="Email Address" /> 
            <PhoneFieldElement name="contactPhone" label="Phone" />
            <TextFieldElement name="message" label="Message (optional)" />
          </Stack>

          
          
          <div className="col-md-12">
								
								<button onClick={handleSubmit(props.onSubmit)} disabled={!props.captchaSuccess} className="thm-btn contact-two__btn">Send Message <i className="fa fa-angle-double-right" /></button>{/* /.thm-btn contact-two__btn */}
							</div>
          

        </FormContainer>
        
      </Paper>
      

    

  );
};