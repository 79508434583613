import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class GuidePage extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

        return  <section className="faq-one faq-one__faq-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="faq-one__block">
                <div className="block-title-two text-left">
                  <h3>Below is a Guide's Detail</h3>
                  <span>We Are Forensic Analyst Professional</span>
                </div>{/* /.block-title-two */}
                <div className="accrodion-grp" data-grp-name="faq-one-accrodion">
                  <div className="accrodion ">
                    <div className="accrodion-title">
                      <h4>iDivisibly Forensic Analyst Professional</h4>
                    </div>
                    <div className="accrodion-content">
                      <div className="inner">
                        <p>But we dolor sit amet consectetur adipisicing elit sed do eiusmod tempor incie
                          didunt ut labore et dolore magna aliqua. Ut enim ad minim venam quis notrud
                          exercitation ullamco laboris nisi aliquip commodo consequat.</p>
                      </div>{/* /.inner */}
                    </div>
                  </div>
                  <div className="accrodion">
                    <div className="accrodion-title">
                      <h4>Livisibly Forensic Analyst Professional</h4>
                    </div>
                    <div className="accrodion-content">
                      <div className="inner">
                        <p>But we dolor sit amet consectetur adipisicing elit sed do eiusmod tempor incie
                          didunt ut labore et dolore magna aliqua. Ut enim ad minim venam quis notrud
                          exercitation ullamco laboris nisi aliquip commodo consequat.</p>
                      </div>{/* /.inner */}
                    </div>
                  </div>
                  <div className="accrodion">
                    <div className="accrodion-title">
                      <h4>Livisibly Forensic Analyst Professional</h4>
                    </div>
                    <div className="accrodion-content">
                      <div className="inner">
                        <p>But we dolor sit amet consectetur adipisicing elit sed do eiusmod tempor incie
                          didunt ut labore et dolore magna aliqua. Ut enim ad minim venam quis notrud
                          exercitation ullamco laboris nisi aliquip commodo consequat.</p>
                      </div>{/* /.inner */}
                    </div>
                  </div>
                  <div className="accrodion">
                    <div className="accrodion-title">
                      <h4>Livisibly Forensic Analyst Professional</h4>
                    </div>
                    <div className="accrodion-content">
                      <div className="inner">
                        <p>But we dolor sit amet consectetur adipisicing elit sed do eiusmod tempor incie
                          didunt ut labore et dolore magna aliqua. Ut enim ad minim venam quis notrud
                          exercitation ullamco laboris nisi aliquip commodo consequat.</p>
                      </div>{/* /.inner */}
                    </div>
                  </div>
                </div>
              </div>{/* /.faq-one__block */}
              
            </div>{/* /.col-lg-8 */}
            
          </div>{/* /.row */}
        </div>{/* /.container */}
      </section>


        }
}

export default GuidePage