import React from "react";
import _ from "lodash";
import Chart from './chart';
import RGL, { WidthProvider } from "react-grid-layout";

const ReactGridLayout = WidthProvider(RGL);

class BoundedLayout extends React.PureComponent {
    //static defaultProps = {
    //    className: "layout",
    //    charts: {},
    //    rowHeight: 30,
    //    onLayoutChange: function () { },
    //    cols: 12
    //};

    //className: "layout",
    //charts: this.props.page.charts,
    //	rowHeight: 30,
    //	onLayoutChange: function() {},
    //	cols: 12
    //  };


    generateDOM() {
        //console.log(`PROPS: ${JSON.stringify(this.props)}`);
        //console.log(`CHARTS: ${JSON.stringify(this.props.className)}`);

        return Object.entries(this.props.charts).map(([key, value]) => {
            
            return (
                <div key={key}>
                    
                    <Chart chart={this.props.charts[key]} />
                </div>
            );
        }
        );



        //return _.map(_.range(this.props.items), function(i) {
        //  return (
        //    <div key={i}>
        //      <span className="text">{i}</span>
        //    </div>
        //  );
        //});
    }

    generateLayout() {
        //console.log(`KEYS=${Object.keys(this.props.charts)}`)
        return _.map(new Array(...Object.keys(this.props.charts)), (item,i)=>{
            //console.log(`ITEM=${item}`);
            return {
                x: (i * 2) % 12,
                y: 0,
                w: 2,
                h: 1,
                i: item
            };
            
        }
        );
        

        //const p = this.props;
        //return _.map(new Array(p.items), function (item, i) {
        //    const y = _.result(p, "y") || Math.ceil(Math.random() * 4) + 1;
        //    return {
        //        x: (i * 2) % 12,
        //        y: Math.floor(i / 6) * y,
        //        w: 2,
        //        h: y,
        //        i: i.toString()
        //    };
        //});
    }

    onLayoutChange(layout) {
        this.props.onLayoutChange(layout);
    }

    render() {

        const layout = this.generateLayout();
        this.state = { layout };
        return (
            <ReactGridLayout
                layout={this.state.layout}
                onLayoutChange={this.onLayoutChange}
                isBounded={true}
                {...this.props}
            >
                {this.generateDOM()}
            </ReactGridLayout>
        );
    }
}

export default BoundedLayout;