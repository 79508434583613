import { SESClient, SendEmailCommand } from "@aws-sdk/client-ses";
import amplifyConfig from '../../amplifyconfiguration.json';
import { fetchAuthSession } from 'aws-amplify/auth';


const createSendEmailCommand = (toAddress, fromAddress, data) => {
    const htmlBody=`
    <h4>Contact Full Name:</h4> ${data.fullName}
    <br>
    <h4>Contact Email:</h4> ${data.contactEmail}
    <br>
    <h4>Contact Phone:</h4> ${data.contactPhone}
    <br>
    <h4>Message:</h4> ${data.message}
    `

    const textBody=`
    Contact Full Name: ${data.fullName}
    
    Contact Email: ${data.contactEmail}
    
    Contact Phone: ${data.contactPhone}
    
    Message: ${data.message}
    `

    return new SendEmailCommand({
        Destination: {
            /* required */
            CcAddresses: [
                /* more items */
            ],
            ToAddresses: [
                toAddress,
                /* more To-email addresses */
            ],
        },
        Message: {
            /* required */
            Body: {
                /* required */
                Html: {
                    Charset: "UTF-8",
                    Data: htmlBody,
                },
                Text: {
                    Charset: "UTF-8",
                    Data: textBody,
                },
            },
            Subject: {
                Charset: "UTF-8",
                Data: `iDivisibly Contact Request from ${data.fullName}`,
            },
        },
        Source: fromAddress,
        ReplyToAddresses: [
            /* more items */
        ],
    });
};

export default async function sendContactEmail(data) {
    const fromEmail = 'support@idivisibly.com'
    const toEmail = 'support@idivisibly.com'

    const sendEmailCommand = createSendEmailCommand(
        toEmail,
        fromEmail,
        data
    );
    //console.log(`sendEmailCommand=${sendEmailCommand}`)

    try {
        // Get the AWS Region.
        const region = amplifyConfig.aws_project_region
        //console.log(`region=${region}`)

        // get current user credentials
        const { credentials } = await fetchAuthSession();

        // Create SES service object.
        const sesClient = new SESClient({ region: region, credentials: credentials });
        return await sesClient.send(sendEmailCommand);
    } catch (e) {
        console.error("Failed to send email.");
        console.error(e)
        return e;
    }
}

