import React from 'react';
import Navbar from './global-components/navbar-v2-livisibly';
import PageHeader from './global-components/page-header-guide';
import GuidePage from './section-components/guide-v3-livisibly';
import Footer from './global-components/footer-v2';

const GuideDivisibly = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Divisibly Guide Page" />
        <GuidePage/>
        <Footer />
    </div>
}

export default GuideDivisibly

