import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { signOut } from 'aws-amplify/auth';

async function handleSignOut() {
  try {
	
	console.log('signing out...')
    await signOut();
  } catch (error) {
    console.log('error signing out: ', error);
  }
}

class SignOut extends Component {

    render() {
        let publicUrl = process.env.PUBLIC_URL+'/'
        let imgattr = 'logo'
        let anchor = '#'
        return (
        
		<Link to={handleSignOut}>Home</Link>
		


        )
    }
}


export default SignOut