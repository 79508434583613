import React from 'react';
import './popup.css';


const Modal = ({ open, onClose }) => {
  console.log('MODAL GOT HERE 1');

  if (!open) return null;
  console.log('MODAL GOT HERE 2');
  return (
    <div onClick={onClose} className='overlay'>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className='modalContainer'
      >
        
        <div className='modalRight'>
          <p className='closeBtn' onClick={onClose}>
            X
          </p>
          <div className='content'>
            <p>This is a test POPUP for DIVISIBLY</p>
            <h1>Click to Money talk</h1>
            <p>Forensic Accounting Analyst</p>
          </div>
          <div className='btnContainer'>
            <button className='btnPrimary'>
              <span className='bold'>YES</span>, I love Money
            </button>
            <button className='btnOutline'>
              <span className='bold'>NO</span>, thanks
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;