import * as d3 from "d3";
import { useEffect, useRef, useState} from "react";
import {postNeo4j} from './dbcall';
//import cfgs from './tiles.json';

const GroupedBarChart = ({ tile_key, chart }) => {
  const ref = useRef();
  //let cfg = cfgs[tile_key];
  let qry = chart.query;
  
  

  //const [data, setChartData] = useState([]);

  async function postChartQuery(qry) {
    
    
    //console.log('calling postNeo4j');
    const result = await postNeo4j(qry);

    //console.log('entering postNeo4j.then');
    //console.log('results....');
    
    //console.log(`CHART RESULT ${JSON.stringify(result)}`);
    //setChartData(result);
    
    return result;
}

  useEffect(() => {
    // set the dimensions and margins of the graph
    const margin = {top: 100, right: 20, bottom: 50, left: 190};
    const width = 450 - margin.left - margin.right;
    const height = 350 - margin.top - margin.bottom;

    // append the svg object to the body of the chart
    const svg = d3
      .select(ref.current)
      .append("svg")
      .attr("width", "100%")
      .attr("height", "100%")
      .attr("viewBox", "0 0 450 350")
      .attr("preserveAspectRatio", "xMinYMin")
    .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);
  

    // Parse the Data
    //d3.csv(
    //  "https://raw.githubusercontent.com/holtzy/data_to_viz/master/Example_dataset/7_OneCatOneNum_header.csv"
    postChartQuery(qry).then(function (data) {

      const sumbyCategorySubcategory = d3.rollups(data, v => d3.sum(v, d => d.Result), d => d.Category, d => d.SubCategory)
      const catKey = Array.from(sumbyCategorySubcategory).map(d => d[0])
      const subCatKeys = Array.from(Array.from(sumbyCategorySubcategory)[0][1]).map(d=>d[0])
      const catKeys = catKey.sort(d3.ascending)



      // X axis
      const xScale = d3.scaleLinear()
      .domain([0, d3.max(data.map(d => d.Result))]).nice()
      .range([0, width]);

      //const x = d3
      //  .scaleBand()
      //  .range([0, width])
      //  .domain(data.map((d) => d.Category))
      //  .padding(0.2);

      svg
  .append('g')
  .attr("transform", "translate(0, "+ height +")")
  .call(d3.axisBottom(xScale).tickSize(0).ticks(5).tickPadding(6).tickFormat(d3.format(".1s")))
  .call(d => d.select(".domain").remove());


      //svg
      //  .append("g")
      //  .attr("transform", `translate(0, ${height})`)
      //  .call(d3.axisBottom(x))
      //  .selectAll("text")
      //  .attr("transform", "translate(-10,0)rotate(-45)")
      //  .style("text-anchor", "end");

      // Add Y axis
      // y scale and Axis
const yScale = d3.scaleBand()
.domain(catKeys)
.range([0, height])
.padding(.2);
svg
.append('g')
.call(d3.axisLeft(yScale).tickSize(0).tickPadding(8));

// set subgroup sacle
const ySubgroups = d3.scaleBand()
  .domain(subCatKeys)
  .range([0, yScale.bandwidth()])
  .padding([0.05])

  // color palette
const color = d3.scaleOrdinal()
.domain(subCatKeys)
.range(['#0072BC','#8EBEFF'])

// set vertical grid line
const GridLine = () => d3.axisBottom().scale(xScale);
svg
  .append("g")
    .attr("class", "grid")
  .call(GridLine()
    .tickSize(height,0,0)
    .tickFormat("")
    .ticks(6)
);

// create a tooltip
const tooltip = d3.select("body")
  .append("div")
    .attr("id", "chart")
    .attr("class", "tooltip");


    // tooltip events
const mouseover = function(d) {
  tooltip
    .style("opacity", .8)
  d3.select(this)
    .style("opacity", .5)
}

const mousemove = function(event, d) {
  const formater =  d3.format(",")
    tooltip
      .html(formater(d[1]))
      .style("top", event.pageY - 10 + "px")
      .style("left", event.pageX + 10 + "px");
}

const mouseleave = function(d) {
  tooltip
    .style("opacity", 0)
  d3.select(this)
    .style("opacity", 1)
}

// create bars
let bars = svg.append("g")
  .selectAll("g")
  .data(sumbyCategorySubcategory)
  .join("g")
     .attr("transform", d => "translate(0, " + yScale(d[0]) +")")
  .selectAll("rect")
  .data(d => { return d[1] })
  .join("rect")
     .attr("x", xScale(0))
     .attr("y", d => ySubgroups(d[0]))
     .attr("width", d => xScale(d[1]))
     .attr("height", ySubgroups.bandwidth())
     .attr("fill", d=>color(d[0]))
  .on("mouseover", mouseover)
  .on("mousemove", mousemove)
  .on("mouseleave", mouseleave);

  // set title
svg
.append("text")
  .attr("class", "chart-title")
  .attr("x", -(margin.left)*0.7)
  .attr("y", -(margin.top)/1.5)
  .attr("text-anchor", "start")
.text("Individual asylum applications registred by region | 2019-2020")

// set Y axis label
svg
.append("text")
  .attr("class", "chart-label")
  .attr("x", width/2)
  .attr("y", height+margin.bottom/2)
  .attr("text-anchor", "middle")
.text("Displaced population")

      //let ymax=Math.max(...data.map((d) => d.Result))
      //const roundnearest=Math.pow(10,(Math.floor(Math.log10(ymax)))); // round up to next nearest one of these, based on power of 10, e.g. 450 -> 100
      //ymax=(1+Math.floor(ymax/roundnearest))*roundnearest;  // round up the y axis max based on the last equation

      
      //const y = d3.scaleLinear().domain([0, ymax]).range([height, 0]);
      //svg.append("g").call(d3.axisLeft(y));
      
      // add Y axis label
      //svg.append("text")
      //.attr("transform", "rotate(-90)")
      //.attr("y", 0 - margin.left)
      //.attr("x",0 - (height / 2))
      //.attr("dy", "1em")
      //.style("text-anchor", "middle")
      //.text(chart.y_label); 

      // add chart label
      //svg.append("text")
      //  .attr("x", (width / 2))             
      //  .attr("y", 0 - (margin.top / 2))
      //  .attr("text-anchor", "middle")  
      //  .style("font-size", "16px") 
      //  .style("text-decoration", "underline")  
      //  .text(chart.title);

      // Bars
      //svg
      //  .selectAll("mybar")
      //  .data(data)
      //  .join("rect")
      //  .attr("x", (d) => x(d.Category))
      //  .attr("y", (d) => y(d.Result))
      //  .attr("width", x.bandwidth())
      //  .attr("height", (d) => height - y(d.Result))
      //  .attr("fill", chart.fillcolor);
    });
  }, []);

  return <svg width={460} height={400} id="barchart" ref={ref} />;
};

export default GroupedBarChart;