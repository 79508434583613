import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class ServiceV5_Divisibly extends Component {

    render() {

    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'

    return	<section className="service-two service-two__service-page  go-top">
		  <div className="container">
		    <div className="row">
		      <div className="col-lg-5">
		        <div className="service-two__block">
		          <div className="block-title-two text-left">
		            <p></p>
		            <h3>AI Assisted Forensic <br /> Accounting for Family Law</h3>
		          </div>{/* /.block-title-two */}
		          <p>Divorce is both a legal issue and a money problem that requires: </p>
		          <ul className="list-unstyled video-one__list">
		            <li>
		              <i className="far fa-check" />
		              Dividing what you own and what you owe
		            </li>
		            <li>
		              <i className="far fa-check" />
		              Deciding spousal support
		            </li>
		            <li>
		              <i className="far fa-check" />
                      Calculating child support
		            </li>

					<br/>

					<p>However, due to cost, only 1% of divorce cases involve a financial expert to assist in determining an equitable financial outcome information. Until Divisibly. </p>
					<br/>
					<p>Livisibly automatically extracts transaction data from bank/credit card PDF statements and tax returns. Divisibly then highlights important financial insights specific to the user's divorce case.</p>
		            
		          </ul>{/* /.list-unstyled video-one__list */}
		          <Link to="/service" className="thm-btn">Sign Up <i className="fa fa-angle-double-right" /></Link>
		          {/* /.thm-btn */}
		        </div>{/* /.service-two__block */}
		      </div>{/* /.col-lg-5 */}
		      
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>

        }
}

export default ServiceV5_Divisibly