import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import ReCAPTCHA from "react-google-recaptcha";
import ContactForm from '../contact-components/contact-form';
import sendContactEmail from '../contact-components/send_email';

export default function Contact() {

	const [captchaSuccess, setCaptchaSuccess] = useState();
	const [formData, setFormData] = useState();

	let publicUrl = process.env.PUBLIC_URL + '/'
	const onCaptchaChange = (value) => { setCaptchaSuccess(value) };
	

	const onSubmit = (data) => {setFormData(data); sendContactEmail(data)}
	const onCancel = () => console.log("Clicked Cancel");


	return (<div>
		<section className="contact-two">
			<div className="container">
			<div className="contact-two__block">
							<h3>Schedule to talk with Livisibly's Team</h3>
						</div>{/* /.contact-two__block */}
				
				<ContactForm captchaSuccess={captchaSuccess} onSubmit={onSubmit}  onCancel={onCancel}/>
				{/*<div><p>Form Data: {JSON.stringify(formData)}</p></div>*/}
				<div style={
					{marginTop: "10px"}
					}>
				<ReCAPTCHA
								sitekey="6LfPlWIpAAAAANs8OzC_q57rTD1gm-88ojZwiYEr"
								onChange={onCaptchaChange}
								
							/>
				</div>
			</div>{/* /.container */}
			
		</section>{/* /.contact-two */}
		
		
	</div>
	)
}


