import React from 'react';
//import Navbar from './global-components/navbar-v2';
import Navbar from './global-components/navbar-v2-livisibly';
import PageHeader from './global-components/page-header';
import Page_header_v1_divisibly from './global-components/page-header-v1-livisibly';
import Service from './section-components/service-v5';
import Analysis_Body_Divisibly from './section-components/analysis-body-livisibly';
import Cta from './section-components/cta-v10';
import FaqV2 from './section-components/faq-v2';
import Testimonial from './section-components/testimonial-v2';
import Brand from './section-components/brand-v4';
import Case from './section-components/case';
import Footer from './global-components/footer-v2';
import Footer_v2_divisibly from './global-components/footer-v2';
import {useParams} from 'react-router-dom';
import pages from './analytics-components/analysis-pages.json';


const Analysis_V1_Divisibly = () => {
    
    let {pageid}=useParams();
    //console.log(`pageid=${pageid}`)
    let page=pages[pageid];
    //console.log(`page=${JSON.stringify(page)}`)


    return <div>
        <Navbar />
        <Page_header_v1_divisibly headertitle={page.title}  />
        <Analysis_Body_Divisibly page={page}/>
        <Footer_v2_divisibly />
    </div>
}

export default Analysis_V1_Divisibly


