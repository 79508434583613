import TextFieldElement from './rh-text-field-element'



export default function PhoneFieldElement(props) {
        


  return (
    <TextFieldElement
      {...props}
      validation= {{
        pattern: {
            value: 
            // eslint-disable-next-line no-useless-escape
            /^[+]?(1\-|1\s|1|\d{3}\-|\d{3}\s|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/g,
            message: 'Please enter a valid phone number'
        }
      }}
      
    />
  )
}