import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import config from './amplifyconfiguration.json';
import AppRoutes from './AppRoutes';
import { post } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';



const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();


Amplify.configure(config);

//Amplify.configure(config, {
//    API: {
//      REST: {
//        headers: async () => {
//            console.log(`authToken: ${authToken}`);
//          return { Authorization: authToken };
//        }
//      }
//    }
//  });



//async function postNeo4j() {
//    try {
//        const restOperation = post({
//            apiName: 'neo4jquery',
//            path: '/neo4j/query',
//            options: {
//                body: {
//                    query: "Match (n:Transaction) return count(n) as Result"
//                }
//            }
//        });
//        await restOperation.response;
//        console.log('POST call succeeded');
//        console.log(restOperation.response);
//    } catch (e) {
//        console.log('POST call failed: ', e);
//    }
//}


function App({ signOut, user }) {
    //postNeo4j();
    return (

        <div>
            
            

                <AppRoutes />
            

        </div>
     
    );
}

export default withAuthenticator(App);