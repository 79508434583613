import React, { Component } from 'react';
import Chart from './chart';


class SingleChartPage extends Component {

	render() {
		let page = this.props.page
		let publicUrl = process.env.PUBLIC_URL + '/'
		let imagealt = 'image'

		
		return <Chart chart={page.chart}/>
		
		

	}
}

export default SingleChartPage