import { post } from 'aws-amplify/api';

export async function postNeo4j(qry) {
    //console.log('POST call initiating');
    
        const restOperation = post({
          apiName: 'neo4jquery',
          path: '/neo4j/query',
          options: {
            body: {
              query: qry,
            }
          }
        });
        const { body } = await restOperation.response;
        const json = await body.json();
        //console.log('POST call succeeded');
        //console.log(json);
        return json;
    
    

}