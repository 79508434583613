import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Navbar extends Component {
	
    render() {
        let publicUrl = process.env.PUBLIC_URL+'/'
        let imgattr = 'logo'
        let anchor = '#'
        return (
           <header className="site-header-one go-top">
			  <nav className="main-nav__one stricky">
			    <div className="container-fluid">
			      <div className="main-nav__logo-box">
			        <Link to="/">
			          <img src={publicUrl+"assets/images/rb.png" } alt={ imgattr } />
			        </Link>
			        <a href="#" className="side-menu__toggler"><i className="fa fa-bars" /></a>
					
			      </div>{/* /.main-nav__logo-box */}


			      <div className="main-nav__main-navigation">
			        <ul className=" main-nav__navigation-box">
					
					
					<li><Link to="/about">About</Link></li>	
			         
			          <li>
			            <Link to="/contact">Contact</Link>
					
			          </li>
					<li>
					  <a href="https://www.linkedin.com/webtend/"><i class="fab fa-linkedin fa-2x"></i></a>
					</li>
			        </ul>

				



			      </div>{/* /.main-nav__main-navigation */}
			      <div className="main-nav__right">
				 
			        <Link to="/contact" className="thm-btn">Log in<i className="fa fa-angle-right" /></Link>
			        {/* /.thm-btn */}

					
				
			


					
			      </div>{/* /.main-nav__right */}
			    </div>
			  </nav>
			</header>
        )
    }
}


export default Navbar