import { BrowserRouter, HashRouter, Route, Switch } from "react-router-dom";

import HomeV1 from './components/home-v1';
import Home_V1_Divisibly from './components/home-v1-livisibly';
//import About from './components/about';
import About_Divisibly from './components/about-v1-livisibly'
//import Service from './components/service';
import Service_V1_Divisibly from './components/service-v1-livisibly';
import Dashboard_V1_Divisibly from './components/dashboard';
import Analysis_V1_Divisibly from './components/analysis';
import ServiceV2 from './components/service-v2';
import ServiceV5_Divisibly from './components/section-components/service-v5-livisibly';
//import Pricing from './components/pricing';
import Pricing_divisibly from './components/pricing-livisibly';
import Team from './components/team';
import PopupModal from './components/popup-components/popup-modal';
import SignOut from './components/global-components/signout';
import Contact from './components/contact';
import GuideDivisibly from './components/guide'


function AppRoutes() {
    return (

        <BrowserRouter basename="/">

            <Switch>
                <Route exact path="/">
                    <Home_V1_Divisibly />
                </Route>
                <Route exact path="/home">
                    <Home_V1_Divisibly />
                </Route>
                <Route path="/about">
                    <About_Divisibly />
                </Route>
                <Route path="/about/">
                    <About_Divisibly />
                </Route>
                <Route path="/analysis/:pageid">
                    <Analysis_V1_Divisibly />
                </Route>
                <Route path="/service-v5-divisibly">
                    <ServiceV5_Divisibly />
                </Route>
                <Route path="/pricing">
                <Contact />
                </Route>
                <Route path="/guide">
                    <GuideDivisibly />
                </Route>
                <Route path="/team">
                    <Team />
                </Route>
                <Route path="/contact">
                    <Contact />
                </Route>

                <Route path="/contact/">
                    <Contact />
                </Route>
                <Route path="/modal">
                    <div>
                        <PopupModal />
                    </div>
                </Route>
                <Route path="/modal/">
                    <div>
                        <PopupModal />
                    </div>
                </Route>
                <Route path="/signout">
                    <div>
                        <SignOut />
                    </div>
                </Route>
                <Route path="/signout">
                    <div>
                        <SignOut />
                    </div>
                </Route>          
            </Switch>
        </BrowserRouter>
    );
}

export default AppRoutes;