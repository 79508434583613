import TextFieldElement from './rh-text-field-element'



export default function EmailFieldElement(props) {
        


  return (
    <TextFieldElement
      {...props}
      type="email"
      
    />
  )
}