import React, { useState } from 'react';
import Modal from './modal';

function PopupModal() {
  const [openModal, setOpenModal] = useState(true);

  console.log(`openModal = ${openModal}`)
  

  return (
    <div>
      <button 
        onClick={() => {
          console.log(`clicked, openModal=${openModal}`);
          setOpenModal(true);}
        } 
      >
        Button
      </button>
      <Modal 
      open={openModal} 
      onClose={() => setOpenModal(false)} />
      </div>
  );
}

export default PopupModal;