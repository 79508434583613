import React, { Component } from 'react';
import SingleChartPage from './single-chart-page';
import GridChartPage from './grid-chart-page';


class ChartPage extends Component {

	render() {
		let page = this.props.page
		let publicUrl = process.env.PUBLIC_URL + '/'
		let imagealt = 'image'

		if(page.page_type == 'SINGLE_CHART'){
			return <SingleChartPage page={page}/>
		}
		if(page.page_type == 'GRID_CHART'){
			return <GridChartPage page={page}/>
		}
		return <div>Unknown Page Type:{page.page_type}</div>

	}
}

export default ChartPage