import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import Tile from '../analytics-components/tile';
import BarChart from '../analytics-components/barchart';

class Dashboard_Tiles_Divisibly extends Component {

	render() {

		let publicUrl = process.env.PUBLIC_URL + '/'
		let imagealt = 'image'

		return <section className="service-two service-two__service-page  go-top">
			<div className="container">
				<div className="row">
					
					{/*<div className="col-lg-7">
						<div className="service-two__box-wrap"> 
    <div className="row"> */}
								<div className="col-md-6">
									<Tile
										tile_key="NUM_TRANSACTIONS"
									/>
								</div>{/* /.col-md-6 */}
								<div className="col-md-6">
									<Tile
										tile_key="TOTAL_AMOUNT"
									/>
								</div>{/* /.col-md-6 */}
								<div className="col-md-6">
									<Tile
										tile_key="NUM_CATEGORIES"
									/>
								</div>{/* /.col-md-6 */}
								
							{/*</div>{/* /.row */}
						{/*</div>{/* /.service-two__box-wrap */}
					{/*</div>{/* /.col-lg-7 */} 
				</div>{/* /.row */}
			</div>{/* /.container */}
		</section>

	}
}

export default Dashboard_Tiles_Divisibly