import React from 'react';
//import Navbar from './global-components/navbar';
import Navbar from './global-components/navbar-v1-livisibly';
import Banner from './section-components/bannerlivisibly';
import Brand from './section-components/brand-one';
import WhyChooseUs from './section-components/why-choose-us';
import AboutStyleOne from './section-components/about-style-one';
import AboutStyleTwo from './section-components/about-style-two';
import AboutStyleTwo_Divisibly from './section-components/about-style-two-livisibly';
import Team from './section-components/team';
import Skill from './section-components/skill';
import Portfolio from './section-components/portfolio-style-one';
import Funfact from './section-components/funfact';
import Testimonial from './section-components/testimonial-style-one';
import CTA from './section-components/cta';
import PostSlider from './section-components/post-slider';
import Footer from './global-components/footer';
import Footer_V1_Divisibly from './global-components/footer-v2';


const Home_V1_Divisibly = () => {
    return <div>
        <Navbar />
        <Banner />
        <Footer_V1_Divisibly />
    </div>
}

export default Home_V1_Divisibly

