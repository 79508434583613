import React, { Component } from 'react';
import Chart from './chart';
import BoundedLayout from './grid_layout';


class GridChartPage extends Component {

	render() {
		let page = this.props.page
		let publicUrl = process.env.PUBLIC_URL + '/'
		let imagealt = 'image'

		let layoutProps = {
			className: "layout",
			charts: this.props.page.charts,
			rowHeight: 500,
			onLayoutChange: function() {},
			cols: 12
		  };
		
		//return <Chart chart={page.chart}/>
		return <BoundedLayout {...layoutProps}/>
		
		

	}
}

export default GridChartPage