import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer_v2 extends Component {

	//componentDidMount() {
	//	const $ = window.$;

///		let publicUrl = process.env.PUBLIC_URL + '/'
//		const minscript = document.createElement("script");
//		minscript.async = true;
//		minscript.src = publicUrl + "assets/js/theme.js";
//
//		document.body.appendChild(minscript);
//
//		$('.go-top').find('a').on('click', function () {
//			$(window).scrollTop(0);
//		});
//	}

	render() {

		let publicUrl = process.env.PUBLIC_URL + '/'
		let imgattr = "Footer logo"

		return (
			<div className="site-footer-two">
				<img src={publicUrl + "assets/images/shapes/footer-shape-2-1.png"} alt={imgattr} className="site-footer-two__shape-1" />
				<img src={publicUrl + "assets/images/shapes/footer-shape-2-2.png"} alt={imgattr} className="site-footer-two__shape-2" />
				<div className="site-footer-two__upper">
					<div className="container">
						<div className="row">
							<div className="col-xl-3 col-lg-6">
								<div className="footer-widget footer-widget__about">
									
									
									
								</div>{/* /.footer-widget footer-widget__about */}
							</div>{/* /.col-lg-3 */}
							
							<div className="col-xl-3 col-lg-6">
								<div className="footer-widget footer-widget__about">
									<Link to="/">
										<img src={publicUrl + "assets/images/rb4.png"} alt={imgattr} />
									</Link>
									<p>Livisibly helps family law professionals analyze financial information with AI-assisted document conversion and analytics.</p>
									
								</div>{/* /.footer-widget footer-widget__about */}
							</div>{/* /.col-lg-3 */}

							<div className="col-xl-3 col-lg-6">
								<div className="footer-widget footer-widget__links">
									<h3 className="footer-widget__title">Quick Links</h3>{/* /.footer-widget__title */}
									<ul className="footer-widget__links-list list-unstyled">
									<li><Link to="/home">Home</Link></li>
									<li><Link to="/about">About</Link></li>
									<li><Link to="/contact">Contact</Link></li>
									</ul>{/* /.footer-widget__links-list */}
								</div>{/* /.footer-widget footer-widget__post */}
							</div>{/* /.col-lg-3 */}


							
							
						</div>{/* /.row */}
					</div>{/* /.container */}
				</div>{/* /.site-footer-two__upper */}
				<div className="site-footer-two__bottom">
					<div className="container">
						<p>Livisibly © 2024. All rights reserved.</p>
					</div>{/* /.container */}
				</div>{/* /.site-footer-two__bottom */}
			</div>
		)
	}
}


export default Footer_v2