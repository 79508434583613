import * as d3 from "d3";
import { useEffect, useRef, useState} from "react";
import {postNeo4j} from './dbcall';
//import cfgs from './tiles.json';

const Barchart = ({ tile_key, chart }) => {
  const ref = useRef();
  //let cfg = cfgs[tile_key];
  let qry = chart.query;
  
  

  //const [data, setChartData] = useState([]);

  async function postChartQuery(qry) {
    
    
    //console.log('calling postNeo4j');
    const result = await postNeo4j(qry);

    //console.log('entering postNeo4j.then');
    //console.log('results....');
    
    //console.log(`CHART RESULT ${JSON.stringify(result)}`);
    //setChartData(result);
    
    return result;
}

  useEffect(() => {
    // set the dimensions and margins of the graph
    const margin = { top: 30, right: 30, bottom: 150, left: 60 },
      width = 460 - margin.left - margin.right,
      height = 400 - margin.top - margin.bottom;

    // append the svg object to the body of the chart
    const svg = d3
      .select(ref.current)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Parse the Data
    //d3.csv(
    //  "https://raw.githubusercontent.com/holtzy/data_to_viz/master/Example_dataset/7_OneCatOneNum_header.csv"
    postChartQuery(qry).then(function (data) {
      // X axis
      const x = d3
        .scaleBand()
        .range([0, width])
        .domain(data.map((d) => d.Category))
        .padding(0.2);
      svg
        .append("g")
        .attr("transform", `translate(0, ${height})`)
        .call(d3.axisBottom(x))
        .selectAll("text")
        .attr("transform", "translate(-10,0)rotate(-45)")
        .style("text-anchor", "end");

      // Add Y axis
      let ymax=Math.max(...data.map((d) => d.Result))
      const roundnearest=Math.pow(10,(Math.floor(Math.log10(ymax)))); // round up to next nearest one of these, based on power of 10, e.g. 450 -> 100
      ymax=(1+Math.floor(ymax/roundnearest))*roundnearest;  // round up the y axis max based on the last equation

      
      const y = d3.scaleLinear().domain([0, ymax]).range([height, 0]);
      svg.append("g").call(d3.axisLeft(y));
      
      // add Y axis label
      svg.append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - margin.left)
      .attr("x",0 - (height / 2))
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .text(chart.y_label); 

      // add chart label
      svg.append("text")
        .attr("x", (width / 2))             
        .attr("y", 0 - (margin.top / 2))
        .attr("text-anchor", "middle")  
        .style("font-size", "16px") 
        .style("text-decoration", "underline")  
        .text(chart.title);

      // Bars
      svg
        .selectAll("mybar")
        .data(data)
        .join("rect")
        .attr("x", (d) => x(d.Category))
        .attr("y", (d) => y(d.Result))
        .attr("width", x.bandwidth())
        .attr("height", (d) => height - y(d.Result))
        .attr("fill", chart.fillcolor);
    });
  }, []);

  return <svg width={460} height={400} id="barchart" ref={ref} />;
};

export default Barchart;